<template>
    <div class="fangentirety darkBack">
        <div>智慧化健身房人群画像</div>
        <div class="fangcharts">
           <!-- 第一个 -->
           <div>
               <div class="blueFont">● 运动年龄分布</div>
				<div id="sportAge"></div>
           </div>
           <div>
				<div class="width50 ">
					<div class="blueFont font18">● 男女比例</div>
					<div class="imgGroup">
						<img class="manPic" src="../../../assets/images/man1.png" >
						<img class="womanPic" src="../../../assets/images/woman1.png" >
					</div>
					<div class="legendText">
						<span>男</span>
						<span>女</span>
					</div>
					<div class="proportion">
						<div class="man">{{ratio.man}}</div>
						<div class="woman">{{ratio.woman}}</div>
					</div>
				</div>
           </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            ratio:{
                man:'53%',
			    woman:'47%'
            },
            age:{
                data:[]
            },
        }
    },
    methods: {
        // 绘制运动年龄图
        drawSportAge(number){
            // let myChart = this.$echarts.init(document.getElementById("sportAge"));
            let myChart = this.$echarts.getInstanceByDom(document.getElementById("sportAge"))
            if (myChart == null) { // 如果不存在，就进行初始化
                myChart = this.$echarts.init(document.getElementById("sportAge"));
            }
            
            let option = {
                // legend: {
                //     bottom:0,
                //     left: 'center',
                //     itemWidth:15,
                //     itemHeight:15,
                //     itemGap:5,
                //     textStyle: { //图例文字的样式
                //         color: '#7EA7E2',
                //         fontSize: '0.14rem'
                //     },
                // },
                series: [
                    {
                        type: 'pie',
                        radius: ['28%', '40%'],
                            // 在容器中的位置
                        center:['50%','40%'],
                        avoidLabelOverlap: false,
                        labelLine: {
                            show: true,
                            //设置延长线的长度
                            normal: {
                                length: 15,//设置延长线的长度
                                length2: 10,//设置第二段延长线的长度
                                lineStyle: {
                                    color: "#ffffff"  // 改变标示线的颜色
                                }
                            },
                        }, 
                            // 字体颜色及大小
                        label:{
                            color:'#D2F8FF',
                            textStyle:{fontSize:'0.12rem'},
                        },
                        color:['#1D77D2','#E35D68','#1BBA79','#F45E23'],
                        data: number.data
                    }
                ]
            };
            
            // 使用刚指定的配置项和数据显示图表。
            myChart.setOption(option);
            // 图表自动缩放
            window.addEventListener("resize",function(){
                myChart.resize();
            });
        },
    },
    mounted () {
        let that = this;
        let data={}
        let url = window.appHost+"/api/v1/screen/Venue/user_analysis?businessid="+window.jianshenfangData.id
        let result = window.ajaxRequest(url,JSON.stringify(data),"post")
        // console.log(result)
        let res=result.data
        that.ratio.man=res.sex[0].parent+'%'
        that.ratio.woman=res.sex[1].parent+'%'
        for(let i in res.age){
            that.age.data.push({
                value:res.age[i].parent,
                name:res.age[i].title
            });
        }
        // console.log(that.age)
		that.drawSportAge(that.age);
    }
}
</script>

<style scoped>
.fangentirety{
    width:5.94rem;
    height:3.46rem;
    padding: 0.2rem;
    color:#FFFFFF;
    font-size: 0.2rem;
    position: relative;
}
.fangcharts{
    display: flex;
    justify-content: space-between;
    font-size: 0.18rem;
    margin-top:0.20rem;
}
.fangcharts>div{
    width:50%;
}
#sportAge{
    width:2.00rem;
    height:2.30rem;
    margin-left:0.2rem;
}


.font18{ font-size: 0.18rem; }
.imgGroup{
    width: 1.85rem;
    display: flex;
    justify-content: space-between;
    padding-left: 0.1rem;
    margin-top: 20%;
}
.manPic{
    width: 0.39rem;
    height: 0.46rem;
}
.womanPic{
    width: 0.43rem;
    height: 0.46rem;
}
.legendText{
    display: flex;
    justify-content: space-between;
    color: #7B8CB7;
    font-size: 0.12rem;
    width: 1.85rem;
    margin: 0.15rem 0rem 0.05rem 0.1rem;
}
.proportion{
    display: flex;
    padding-left: 0.1rem;
    margin-top:0.2rem;
}
.man , .woman{
    color: white;
    text-align: center;
    font-size: 0.14rem
}
.man{
    width: 0.92rem;
    background-color: #0E83C8;
}
.woman{
    width: 0.92rem;
    background-color: #E88A2B;
}
</style>